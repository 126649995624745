import React, { useEffect, useState } from "react";
import { getFooterData, getNavigationData, getHomepageData } from "@/api";
import { ErrorBoundary, Footer, ModuleMatcher2 } from "@/components";
import { NextPage } from "next";
import { useRouter } from 'next/router';  
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import Head from "next/head";
import NavigationMenu from "@/components/NavigationMenu/NavigationMenu";
import getTargetUrl from "@/api/getTargetUrl";
import { MoonLoader } from 'react-spinners';

const PageNotFound: NextPage = () => {

    const [navigationData, setNavigationData] = useState<any>();
    const [footerData, setFooterData] = useState<any>();
    const [pagesData, setPagesData] = useState<any>();
    const [p404Data, setp404Data] = useState<any>();
    const [moduleData, setModuleData] = useState<any>();
    const [language, setLanguage] = useState<any>();
    const [isLoading] = useState(false);
    const { i18n } = useTranslation();
    const router = useRouter();
    const { asPath } = router;

    async function getHeader() {

        return await getNavigationData();
    }

    async function getFooter() {

        return await getFooterData();
    }

    async function getPages() {

        return await getHomepageData();
    }

    async function get404Data() {

        return await fetch(process.env.NEXT_PUBLIC_BE_BASE_PATH + `/rest/v1/SEA-LinMxp/contents/page404?query=xtaxAirports.ti.label=%27${process.env.NEXT_PUBLIC_AIRPORT_REFERENCE}%27&sort=metainfo.publicationdate:DESC&from=0&size=1`);
    }

    async function getModuleData() {

        let excludes = new Array;
        if (p404Data.length > 0) return await Promise.all(p404Data[0]!.lstModule?.filter((item: { xconModules: { linkedcontenttype: any; }; }) => !excludes?.includes(item.xconModules.linkedcontenttype)).map( async (moduleItem: { xconModules: { linkedcontenttype: any; targeturl: string; }; id: any; }) => {

            var moduleData: any;
        
            switch (moduleItem.xconModules.linkedcontenttype) {
            
              case "m01":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m02":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m03":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m04":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m05":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m06":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl)
              case "m07":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m08":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m09":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m10":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m11":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m12":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m13":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m14":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m15":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m16":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m17":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m18":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m19":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
              case "m20":
                moduleData = await getTargetUrl(moduleItem.xconModules.targeturl);
            }
        
            return {
              linkedcontenttype: moduleItem.xconModules.linkedcontenttype,
              id: moduleItem.id,
              moduleData: moduleData
            };
        }))
    }

    useEffect(() => {

        getHeader()
            .then(response => {
                setNavigationData(response);
            })

        getFooter()
            .then(response => {
                setFooterData(response.data);
            })

        getPages()
        .then(response => {
            setPagesData(response);
        })

        get404Data()
            .then(response => response.json())
            .then(response => setp404Data(response.data))   

    }, []);

    useEffect(() => {
        
        if (p404Data !== undefined) {

            getModuleData()
                .then(response => setModuleData(response))
        }

    }, [p404Data]);

    useEffect(() => {

        let lang = asPath.startsWith('/it') ? 'it' : 'en';
        i18n.changeLanguage(lang);
        setLanguage(lang);

    }, []);

    return (
        <React.Fragment>
        
            <Head>
                <meta name="robots" content="noindex, nofollow"></meta>
                <meta name="googlebot" content="noindex, nofollow"></meta>
            </Head>

            <div className='font-primary font-normal'>
                
            <Toaster position="bottom-right" />

            {/* <ErrorBoundary customMessage="Alert is broken">
                <Alert language={language} targetUrl={process.env.NEXT_PUBLIC_BE_BASE_PATH + "/rest/v1/SEA-LinMxp/contents/alert"} />
            </ErrorBoundary> */}

            { navigationData ?
                <ErrorBoundary customMessage="Navigation menu is broken">
                    <NavigationMenu
                        language={language}
                        navigationData={navigationData}
                        hostPageData={pagesData ? pagesData.data[0] : null}
                    />
                </ErrorBoundary>
            : null }


            <main className='min-h-[400px]'>
              { isLoading ?
                <div className="h-[400px] relative flex justify-center items-center">
                    <MoonLoader className="text-[#6ABACE]" />
                </div> :
                <ModuleMatcher2
                    lang={language}
                    moduleData={moduleData}
                />
              }
            </main>
            { footerData ?
                <ErrorBoundary customMessage="Footer is broken">
                    <Footer dataFooter={footerData} lang={language} />
                </ErrorBoundary>
            : null }

            </div>
        </React.Fragment>

    )
};

export default PageNotFound;
