import { IPageDTOResponse } from "@/interfaces/IPageDTO";


export default async function getHomepageData() {

  const homepageResult = await fetch(process.env.NEXT_PUBLIC_BE_BASE_PATH + "/rest/v1/SEA-LinMxp/contents/genericPage?query=enuPageCategory=%27Homepage%27&sort=metainfo.publicationdate:DESC");/* sort=metainfo.publicationdate:DESC&from=0&size=1" ); */
  const resultPages: IPageDTOResponse= await homepageResult.json();
  const filteredResult: IPageDTOResponse = {...resultPages, data: resultPages.data.filter(itemPage => itemPage.xtaxAirports.ti.find(ti => ti.label.toLowerCase() === process.env.NEXT_PUBLIC_AIRPORT_REFERENCE?.toLowerCase()))}
  /* const data: IPageDTOResponse = filteredResult; */

  return !filteredResult.data.length ? resultPages : filteredResult;
}

