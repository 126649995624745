import { IPageDTOResponse } from "@/interfaces/IPageDTO";

export default async function getFooterData() {

  const footerResult = await fetch(
    process.env.NEXT_PUBLIC_BE_BASE_PATH +
    `/rest/v1/SEA-LinMxp/contents/footer?query=xtaxAirport.ti.label=%27${process.env.NEXT_PUBLIC_AIRPORT_REFERENCE}%27&sort=metainfo.publicationdate:DESC&from=0&size=1`
  );

  const footerData: IPageDTOResponse = await footerResult.json();

  return footerData;
}
