import { IPageDTOResponse } from "@/interfaces/IPageDTO";

export default async function getTargetUrl(url: string) {

  const targetUrlResult = await fetch(url);

  const targetData: IPageDTOResponse = await targetUrlResult.json();

  return targetData;
}
